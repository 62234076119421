// @import '~bootstrap/scss/bootstrap';
// @import './assets/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Poppins:wght@600;700&display=swap');
@import './assets/scss/bootstrap.scss';
@import './assets/css/style.css';

.notification-success {
    background-color: #46344E !important;
}

.btn.btn-link:focus {
    box-shadow: none;
}

.carousel-caption {
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container>.form-control {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: var(--dark);
    border: 15px solid var(--dark);
    border-radius: 50px;
    height: 4rem;
    width: 4rem;
}

.facts-overlay h1 {
    -webkit-text-stroke: 2px #8D9297;
}

.pricing-box {
    -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
    padding: 35px 50px 85px !important;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing-box .plan {
    font-size: 34px;
  }
  
  .pricing-badge {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
  }
  
  .pricing-badge .badge {
    float: right;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    right: -60px;
    top: 25px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #ffffff;
    background: #46344e;
  }